<template>
  <v-dialog v-model="value" persistent max-width="800px">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title class="bg-gradient-primary-2">
          <span class="white--text"> Add Account Number</span>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="6">
              <ac-select
                label="Staff"
                v-model="staff"
                :items="staffOptions"
                class="company-select"
                :validation-rules="[v => !!v || 'Field is required']"
                :disabled="checkObject(this.editItem)"
              />
            </v-col>
            <v-col cols="6">
              <ac-input
                v-model="number"
                label="Number"
                type="number"
                :validation-rules="[v => !!v || 'Field is required']"
                outlined
                required
              ></ac-input>
            </v-col>
            <v-col cols="6">
              <ac-select
                label="Account Type"
                v-model="accountType"
                :items="['Brokerage', 'Discretionary Management']"
                class="company-select"
              />
            </v-col>
            <v-col cols="6">
              <ac-input v-model="holderName" label="Account Holder's Name" outlined></ac-input>
            </v-col>
            <v-col cols="6">
              <ac-input
                v-model="broker"
                label="Broker / Investment manager"
                :validation-rules="[v => !!v || 'Field is required']"
                outlined
                required
              ></ac-input>
            </v-col>
            <v-col cols="6">
              <ac-select
                label="Statement required"
                v-model="statementRequired"
                :items="['Yes', 'No']"
                class="company-select"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ac-date-picker
                v-model="activeDate"
                label="Active Since"
                :validation-rules="[v => !!v || 'Field is required']"
                outlined
                required
              />
            </v-col>
            <v-col>
              <ac-date-picker v-model="closeDate" label="Closing Date" outlined />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="notes" label="Notes" outlined dense hide-details=""></v-textarea>
            </v-col>
            <v-col>
              <ac-file-upload ref="fileUpload" @getLinks="onFileUpload" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <ac-button title="Cancel" color="error" outlined @click="onClose(true)" />
          <ac-button title="Submit" color="success" :loading="loading" @click="onSubmit" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UpsertAccountNumberModal',
  components: {
    AcButton: () => import('@/components/AcButton.vue'),
    AcInput: () => import('@/components/AcInput.vue'),
    AcSelect: () => import('@/components/AcSelect.vue'),
    AcDatePicker: () => import('@/components/AcDatePicker.vue'),
    AcFileUpload: () => import('@/components/AcFileUpload.vue'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      valid: true,
      // * form
      staff: null,
      number: null,
      accountType: null,
      statementRequired: 'Yes',
      holderName: null,
      broker: null,
      activeDate: null,
      closeDate: null,
      notes: null,
      files: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('app', ['loggedInUser', 'selectedCompany']),
    ...mapGetters('paDealing', ['companyStaff']),
    staffOptions() {
      const options = this.companyStaff?.map(e => ({
        id: e.id,
        name: e.name,
      }))
      return options
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          if (this.checkObject(this.editItem)) {
            this.staff = this.editItem.userID
            this.number = this.editItem.accountNumber
            this.accountType = this.editItem.accountType
            this.statementRequired = this.editItem.statementRequired
            this.holderName = this.editItem.holderName === '-' ? '' : this.editItem.holderName
            this.broker = this.editItem.broker
            this.activeDate = this.editItem.activeDate
            this.closeDate = this.editItem.closeDate
            this.notes = this.editItem.notes
          }
        }
      },
    },
  },
  methods: {
    ...mapActions('paDealing', [
      'createAccountNumber',
      'updateAccountNumber',
      'accountsUploadFile',
    ]),
    onClose(preventRefetch) {
      this.$refs.form.resetValidation()
      this.staff = null
      this.number = null
      this.accountType = null
      this.statementRequired = 'Yes'
      this.holderName = null
      this.broker = null
      this.activeDate = null
      this.closeDate = null
      this.notes = null
      this.files = null
      this.loading = false
      this.$refs.fileUpload.clear()
      this.$emit('input', false)
      this.$emit('close', !preventRefetch)
    },
    async onSubmit() {
      this.$refs.form.validate()
      if (this.valid) {
        this.loading = true
        // * create new
        if (!this.checkObject(this.editItem)) {
          const payload = {
            LoggedUserId: this.loggedInUser.userId,
            accountNumber: {
              userId: this.staff,
              number: this.number,
              accountType: this.accountType || '',
              holderName: this.holderName || '',
              broker: this.broker,
              statementRequired: this.statementRequired,
              activeSince: this.activeDate,
              closingDate: this.closeDate || '',
              notes: this.notes || '',
              file: this.files || [],
            },
          }
          console.log({ payload })
          const { success, message } = await this.createAccountNumber(payload)
          if (success) {
            this.loading = false
            this.AcNotify({
              type: 'success',
              message,
              position: 'bottom',
            })
            this.onClose()
          } else {
            this.loading = false
            this.AcNotify({
              type: 'error',
              message,
              position: 'bottom',
            })
          }
        }
        // * edit old
        else {
          const payload = {
            userID: this.editItem.id,
            content: {
              LoggedUserId: this.loggedInUser.userId,
              editUserAcc: {
                accountType: this.accountType,
                activeSince: this.activeDate,
                broker: this.broker,
                closingDate: this.closeDate || '',
                holderName: this.holderName,
                notes: this.notes,
                number: this.number,
                statementRequired: this.statementRequired,
                file: this.files || [],
              },
            },
          }
          const { success, message } = await this.updateAccountNumber(payload)
          if (success) {
            this.loading = false
            this.AcNotify({
              type: 'success',
              message,
              position: 'bottom',
            })
            this.onClose()
          } else {
            this.loading = false
            this.AcNotify({
              type: 'error',
              message,
              position: 'bottom',
            })
          }
        }
      }
    },
    onFileUpload(urls) {
      this.files = urls
    },
  },
}
</script>

<style lang="scss" scoped>
.video {
  max-width: 600px;
}
</style>
